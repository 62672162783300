import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  SampleViewModel,
  FileParameter,
  BaseStatus,
  ShapeListViewModel,
  SampleTypeListViewModel,
  ProductCategoryViewModel,
  SampleTypeItem,
  SampleLibraryType
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  SampleAction,
  SampleGetter,
  sampleNamespace
} from '@/store/sample/sample.module-types';
import { dispatchSampleAction } from '@/store/sample/sample.dispatch';
import { sampleListDispatcher } from '@/views/sample/sampleList/store/sample-list.module';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import {
  sampleTypeListDispatcher,
  SampleTypeListInput,
  sampleTypeListNamespace
} from '@/views/sampleType/sampleTypeList/sample-type-list.modules';
import {
  PRODUCT_CATEGORY_STATE_NAMESPACE,
  ProductCategoryAction,
  ProductCategoryState
} from '@/store/productCategory/productCategory.module-types';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    vSelect
  },
  computed: {
    ...mapState(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryState.productCategories
    ])
  },
  methods: {
    ...mapActions(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryAction.fetchProductCategories
    ])
  }
})
export default class SampleFormModal extends Vue {
  @sampleNamespace.Getter(SampleGetter.sample)
  sample!: SampleViewModel;

  @sampleTypeListNamespace.Getter(ListGetter.state)
  sampleTypeListState!: ListGetterTypes<
    SampleTypeListViewModel,
    SampleTypeListInput
  >[ListGetter.state];

  //Vuex
  productCategories!: ProductCategoryViewModel[];
  fetchProductCategories!: () => Promise<void>;

  input = this.getDefaultInputValue();

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  sampleStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);
  shapeTypeOptions: DropdownOption[] = [];
  sampleTypeOptions: DropdownOption[] = [];
  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  @Watch('sampleTypeListState', { deep: true })
  sampleTypeListUpdated(): void {
    this.sampleTypeOptions = this.sampleTypeListState.items
      .filter((s) => s.type === SampleLibraryType.Career)
      .map(
        (x) =>
          ({
            value: x.id,
            text: x.name
          } as DropdownOption)
      );

    this.shapeTypeOptions = this.sampleTypeListState.items
      .filter((s) => s.type === SampleLibraryType.Shape)
      .map(
        (x) =>
          ({
            value: x.id,
            text: x.name
          } as DropdownOption)
      );
  }

  @Watch('sample')
  sampleUpdated(): void {
    if (this.sample) {
      this.input = {
        image: null,
        name: this.sample.name as string,
        status: this.sample.status as BaseStatus,
        description: this.sample.description as string,
        shapeIds:
          this.sample.sampleTypeItems
            ?.filter((s) => s.type === SampleLibraryType.Shape)
            .map((s) => s.id) ?? [],
        careerIds:
          this.sample.sampleTypeItems
            ?.filter((s) => s.type === SampleLibraryType.Career)
            .map((s) => s.id) ?? [],
        sampleTypeItems: this.sample.sampleTypeItems ?? [],
        productCategoryId: this.sample.productCategoryId as number
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  mounted() {
    if (!this.productCategories || this.productCategories?.length === 0) {
      //this.isLoading = true;
      Promise.all([this.fetchProductCategories()])
        .catch((err) => {
          this.$bvToast.toast('Tải thông tin thất bại', {
            title: 'Đơn hàng',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        })
        .finally(() => {
          //this.isLoading = false;
        });
    }
  }

  getSampleTypes(): void {
    sampleTypeListDispatcher.updateInput({
      itemsPerPage: 1000
    });
    sampleTypeListDispatcher.load();
  }

  getSampleType(): void {
    sampleTypeListDispatcher.updateInput({ itemsPerPage: 1000 });
    sampleTypeListDispatcher.load();
  }

  openCreateModal(): void {
    this.getSampleType();
    this.getSampleTypes();
    dispatchSampleAction(SampleAction.clearSample);
    this.input = this.getDefaultInputValue();
    this.$refs.modal.show();
  }

  async openEditModal(id: number): Promise<void> {
    this.getSampleType();
    this.getSampleTypes();
    dispatchSampleAction(SampleAction.loadSample, id);
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      image: null,
      status: BaseStatus.Active,
      description: '',
      shapeIds: [] as number[],
      careerIds: [] as number[],
      productCategoryId: 0,
      sampleTypeItems: [] as SampleTypeItem[]
    };
  }

  add(): void {
    const client = new ApiClientFactory().sampleClient();
    const image: FileParameter = {
      data: this.input.image,
      fileName: (this.input.image as any).name
    };

    const sampleTypesIds = this.input.careerIds.concat(this.input.shapeIds);
    client
      .create(
        this.input.name,
        this.input.status,
        this.input.description,
        image,
        undefined,
        undefined,
        this.input.productCategoryId,
        sampleTypesIds
      )
      .then(() => {
        sampleListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm mới thành công', {
          title: 'Mẫu',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().sampleClient();
    const image: FileParameter | null = this.input.image
      ? {
          data: this.input.image,
          fileName: (this.input.image as any).name
        }
      : null;
    const sampleTypesIds = this.input.careerIds.concat(this.input.shapeIds);

    client
      .edit(
        this.sample.id,
        this.input.name,
        this.input.status,
        this.input.description,
        image,
        undefined,
        undefined,
        this.input.productCategoryId,
        sampleTypesIds
      )
      .then(() => {
        sampleListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa thành công', {
          title: 'Mẫu',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
    }
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.sample) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
