var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{on:{"click":_vm.openCreateModal}},[_vm._t("default")],2),_c('b-modal',{ref:"modal",attrs:{"id":"modal","cancel-variant":"outline-secondary","ok-title":_vm.$t('Save'),"ok-only":"","centered":"","title":_vm.sample ? 'Chỉnh sửa' : 'Thêm mới',"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'name',"text":'Tên',"required":true}}),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'description',"text":'Mô tả'}}),_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Mô tả","rows":"4"},model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'shapeName',"text":'Hình dạng'}}),_c('validation-provider',{attrs:{"name":"shapeIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('v-select',{attrs:{"multiple":"","id":"shape","label":"text","options":_vm.shapeTypeOptions,"reduce":function (shape) { return shape.value; }},model:{value:(_vm.input.shapeIds),callback:function ($$v) {_vm.$set(_vm.input, "shapeIds", $$v)},expression:"input.shapeIds"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":"sampleTypeId","text":"Ngành nghề","required":false}}),_c('validation-provider',{attrs:{"name":"shapeIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('v-select',{attrs:{"multiple":"","id":"shape","label":"text","options":_vm.sampleTypeOptions,"reduce":function (c) { return c.value; }},model:{value:(_vm.input.careerIds),callback:function ($$v) {_vm.$set(_vm.input, "careerIds", $$v)},expression:"input.careerIds"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":"category","text":_vm.$t('Category'),"required":true}}),_c('validation-provider',{attrs:{"name":"category","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"category","placeholder":_vm.$t('categoryPlaceHolder'),"options":_vm.productCategories,"state":errors.length > 0 ? false : null,"label":"name","reduce":function (cate) { return cate.id; }},model:{value:(_vm.input.productCategoryId),callback:function ($$v) {_vm.$set(_vm.input, "productCategoryId", $$v)},expression:"input.productCategoryId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'status',"text":'Trạng thái',"required":true}}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"status","state":errors.length > 0 ? false : null},model:{value:(_vm.input.status),callback:function ($$v) {_vm.$set(_vm.input, "status", $$v)},expression:"input.status"}},_vm._l((_vm.sampleStatusOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'image',"text":'Hình ảnh',"required":true}}),_c('validation-provider',{attrs:{"name":"image","rules":_vm.sample ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-file',{attrs:{"value":_vm.input.image,"state":errors.length > 0 ? false : null,"name":"Image","accept":"image/*"},on:{"change":_vm.imageChange}}),(_vm.input.image || _vm.sample)?_c('b-img',{staticClass:"mt-2 w-100",attrs:{"thumbnail":"","src":_vm.input.image
                  ? _vm.URL.createObjectURL(_vm.input.image)
                  : (_vm.VUE_APP_API_BASE_HOST + "/" + (_vm.sample.image))}}):_vm._e(),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }