import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import SampleFormModal from '@/components/samples/SampleFormModal.vue';
import SampleFormModalClass from '@/components/samples/SampleFormModal';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { SampleListViewModel } from '@/api/api';
import {
  sampleListDispatcher,
  SampleListInput,
  sampleListNamespace
} from './store/sample-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { BaseStatus } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    SampleFormModal,
    BCardText,
    Pagination,
    BImg,
    BBadge
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: sampleListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ]
})
export default class SampleList
  extends Vue
  implements ListVue<SampleListViewModel, SampleListInput> {
  @sampleListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    SampleListViewModel,
    SampleListInput
  >[ListGetter.state];

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  SAMPLE_STATUS_ENUM = BaseStatus;
  $refs!: {
    sampleFormModal: InstanceType<typeof SampleFormModalClass>;
  };

  sampleStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);

  displaySampleStatus(value) {
    const result = this.sampleStatusOptions.find((x) => x.value === value)
      ?.text;
    return result ? this.$t(result) : '';
  }

  mounted() {
    sampleListDispatcher.load();
  }

  edit(id: number) {
    this.$refs.sampleFormModal.openEditModal(id);
  }

  remove(id: number) {
    const client = new ApiClientFactory().sampleClient();
    client.delete(id).then(() => {
      sampleListDispatcher.load();
      // show toast
      this.$bvToast.toast('Xóa thư viện tem thành công', {
        title: 'Thư viện tem',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  navigateToSampleType() {
    this.$router.push({ path: 'sample-list/sample-types' });
  }

  headers = [
    {
      label: 'Hình',
      field: 'image',
      sortable: false,
      width: '3%'
    },
    {
      label: 'Tên',
      field: 'name'
    },
    {
      label: 'Mô tả',
      field: 'description'
    },
    {
      label: 'Danh mục sản phẩm',
      field: 'productCategoryName',
      width: '20%'
    },
    {
      label: 'Hoạt động',
      field: 'status',
      width: '20%'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
